const sizeComparator = (resp1, resp2) =>
  (resp1.maxWidth || Infinity) - (resp2.maxWidth || Infinity);

const sizeConverter = (resp) =>
  resp.minWidth
    ? `(min-width: ${resp.minWidth}px) ${resp.size.width}px`
    : `${resp.size.width}px`;

const normalizeSrc = (src) => {
  return src.startsWith("/") ? src.slice(1) : src;
};

function cloudflareQueryGenerator(src, options) {
  // const CLOUDFLARE_PATH =
  //   import.meta.env.VITE_CLOUDFLARE_IMAGE_PATH || "/cdn-cgi/image/";

  const { width, height, fit, format = "auto", quality } = options;
  const query = new URLSearchParams();

  if (width) {
    query.append("w", width.toString());
  }
  if (height) {
    query.append("h", height.toString());
  }
  if (fit) {
    query.append("fit", fit.toString());
  }

  if (format) {
    query.append("f", format.toString());
  }

  if (quality) {
    query.append("w", quality.toString());
  }

  return new URL(`https://images.shopdeploy.com/${normalizeSrc(src)}?${query}`);
  // return `https://images.shopdeploy.com/${query.toString()}/${normalizeSrc(
  //   src
  // )}`;
}

function useResponsiveImage(src, responsive, options) {
  let largestSrc = src || "";
  let largestWidth = 0;
  const srcSet = [];

  for (const { size } of responsive) {
    const srcSetUrl = cloudflareQueryGenerator(src, {
      width: typeof size.width === "number" ? size.width : size.width,
      height: typeof size.height === "number" ? size.height : size.height,
      fit: "cover",
      position: "center",
      background: [0x00, 0x00, 0x00, 0x00],
      quality: 80,
      compressionLevel: 9,
      loop: 0,
      delay: 100,
      blurRadius: null,
      rotate: null,
      flip: null,
      crop: null,
      ...options,
    });
    srcSet.push(srcSetUrl + ` ${size.width}w`);

    if (size.width > largestWidth) {
      largestWidth = size.width;
      largestSrc = srcSetUrl;
    }
  }

  const sizes = [...responsive].sort(sizeComparator).map(sizeConverter);

  if (responsive.length === 1 && responsive[0].minWidth != null) {
    sizes.push(`${responsive[0].size.width}px`);
  }

  return {
    ...(srcSet.length > 0 && {
      srcSet: srcSet.join(", "),
      sizes: sizes.join(", "),
    }),
    src: largestSrc,
  };
}

export { useResponsiveImage };
