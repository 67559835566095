import { forwardRef } from "react";
import type { FitEnum } from "sharp";
import { useResponsiveImage } from "~/hook/responsiveImage";

export interface ImageProps extends React.ComponentPropsWithRef<"img"> {
  src: string; // a path within the assets/images directory, can be a nested path
  width?: number; // either width or height is required
  height?: number;
  fit?: keyof FitEnum; // contain is default
  alt: string;
  responsive: Array<any>;
  options: any;
  definedSizes: string;
}
export const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      responsive,
      options = {},
      definedSizes = "",
      width,
      height,
      fit,
      src,
      alt = "",
      ...other
    },
    forwardedRef,
  ) => {
    let imageSizes = responsive || [
      {
        size: {
          width: 720,
        },
        minWidth: 720,
      },
      {
        size: {
          width: 320,
        },
      },
    ];

    let resp = useResponsiveImage(src, imageSizes, options);

    if (definedSizes) {
      resp.sizes = definedSizes;
    }
    // console.log("resp", resp);
    return (
      <img
        ref={forwardedRef}
        alt={alt}
        {...resp}
        // srcSet={srcSet.join(", ")}
        // src={`/cdn-cgi/image/${query.toString()}${src}`}
        // sizes="(min-width: 720px) 720px, 100vw"
        {...{ width, height, ...other }}
      />
    );
  },
);

Image.displayName = "Image";

// screens: {
//   'sm': '640px',
//   // => @media (min-width: 640px) { ... }

//   'md': '768px',
//   // => @media (min-width: 768px) { ... }

//   'lg': '1024px',
//   // => @media (min-width: 1024px) { ... }

//   'xl': '1280px',
//   // => @media (min-width: 1280px) { ... }

//   '2xl': '1536px',
//   // => @media (min-width: 1536px) { ... }
// }
// responsive={[
//     {
//       size: { width: 100, height: 100 },
//       maxWidth: 500,
//     },
//     {
//       size: { width: 600, height: 600 },
//     },
//   ]}
// />
